import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Button } from '@radix-ui/themes';
import config from '../aws-exports';
import './header.css';
import { UserContext } from '../App';
import whiteOverlay from './New-Head.png'; // your existing logo

Amplify.configure(config);

const SignOutIcon = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 24 24"
    fill="none"
    /* stroke="currentColor" so it matches .signout-button's color (#fff) */
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ display: 'block' }}
  >
    {/* The “door” frame */}
    <path d="M14 2h5a1 1 0 011 1v18a1 1 0 01-1 1h-5" />
    {/* The arrow pointing out */}
    <path d="M9 6l-6 6 6 6" />
    <path d="M3 12h11" />
  </svg>
);

/** Example Marketplace Icon (shop/store style) */
const MarketplaceBagIcon = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    style={{ display: 'block' }}
  >
    {/* Bag shape */}
    <path d="M6 2l.01 4M18 2l-.01 4M3 6h18v14a2 2 0 01-2 2H5a2 2 0 01-2-2V6z" />
    {/* Handle arcs (optional) */}
    <path d="M8 6a4 4 0 008 0" />
  </svg>
);
 
export function Header({ signOut }: WithAuthenticatorProps) {
  const { token } = useContext(UserContext);

  // Check if user is admin
  const isAdmin =
    Array.isArray(token?.payload['cognito:groups']) &&
    token?.payload['cognito:groups'].includes('admin');

  return (
    <header>
      {/* External marketplace link pinned at the far left corner */}
      <div className="marketplace-icon-container">
        <a
          href="https://filmassistantio.printful.me/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <MarketplaceBagIcon />
        </a>
      </div>

      {/* The existing glass container in the center */}
      <div className="header-wrapper">
        {/* Left: Logo */}
        <div className="logo-section">
          <img
            src={whiteOverlay}
            alt="Logo"
            className="header-logo"
          />
        </div>

        {/* Middle: Nav */}
        <nav className="nav-section">
          <NavLink to="/home">Home</NavLink>
          <NavLink to="/profile">Profile</NavLink>
          <NavLink to="/prices">Pricing</NavLink>
          <NavLink to="/community">Community</NavLink>
          {isAdmin && <NavLink to="/events">Events</NavLink>}
        </nav>

        {/* Right: Sign out */}
        <div className="signout-section">
        <Button 
            variant="ghost" 
            color="gray" 
            className="signout-button" 
            onClick={signOut}
          >
            <SignOutIcon />
          </Button>
        </div>
      </div>
    </header>
  );
}

export default withAuthenticator(Header);
