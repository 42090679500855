import React from 'react';
import './App.css';
import { useMutation } from "react-query";
import { useState , useEffect, createContext, useCallback} from "react";
import { fetchUserAttributes, fetchAuthSession, AuthSession, JWT} from 'aws-amplify/auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import {withAuthenticator} from '@aws-amplify/ui-react';
import {useAuthenticator,Authenticator} from '@aws-amplify/ui-react';
import {BrowserRouter as Router,Routes,Route, useLocation} from 'react-router-dom';
import {debounce} from "lodash";
import { Cache } from 'aws-amplify/utils';
import { useNavigate } from 'react-router-dom';
import Profile from './components/profile';
import Prices from './components/prices';
import Home from './components/home';
import NotFound from './components/notfound';
import '@aws-amplify/ui-react/styles.css';
import {Amplify} from 'aws-amplify';
import config from './aws-exports';
import '@radix-ui/themes/styles.css';
import axios from 'axios';
import Header from './components/header';
import Footer from './components/footer';
import { toast,Toaster } from 'react-hot-toast';
import { User } from './models/user';
import Community from './components/community';
import Login from './components/login';
import Events from './components/events';
import { Event, EventStatus, Winner, Submission } from './models/event';
import Landing from './components/landing';

export const UserContext = createContext<any>({});

Amplify.configure(config);

const isAdminUser = (token: JWT | undefined) => {
  return Array.isArray(token?.payload['cognito:groups']) && 
         token?.payload['cognito:groups'].includes('admin');
};

const AdminRoute = ({ children }: { children: React.ReactNode }) => {
  const { token } = React.useContext(UserContext);
  const navigate = useNavigate();

  useEffect(() => {
    const isAdmin = token?.payload['cognito:groups']?.includes('admin');
    if (!isAdmin) {
      navigate('/');
    }
  }, [token, navigate]);

  return token?.payload['cognito:groups']?.includes('admin') ? <>{children}</> : null;
};

// Add this new component for protected routes
const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    if (authStatus !== 'authenticated') {
      // If trying to access /home, redirect to landing page
      if (location.pathname === '/home') {
        navigate('/');
      } else {
        // For all other protected routes, redirect to login
        navigate('/login');
      }
    }
  }, [authStatus, navigate, location]);

  return authStatus === 'authenticated' ? <>{children}</> : null;
};

// Add this new component for the login route
const LoginRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home');
    }
  }, [authStatus, navigate]);

  return authStatus !== 'authenticated' ? <>{children}</> : null;
};

// Add this new component for the landing route
const LandingRoute = ({ children }: { children: React.ReactNode }) => {
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();

  useEffect(() => {
    if (authStatus === 'authenticated') {
      navigate('/home');
    }
  }, [authStatus, navigate]);

  return authStatus !== 'authenticated' ? <>{children}</> : null;
};

function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

function AppContent() {
  const { authStatus, signOut: amplifySignOut } = useAuthenticator((context) => [context.authStatus]);
  const navigate = useNavigate();
  const [attributes, setAttributes] = useState<FetchUserAttributesOutput>();
  const [token, setToken] = useState<JWT>();
  const [user, setUser] = useState<User | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState<Event[]>([]);
  const [submissions, setSubmissions] = useState<Submission[]>([]);
  const [recentTitle, setRecentTitle] = useState<string>("Untitled");

  // Getting user data from cognito
  useEffect(() => {
    async function handleFetchUserAttributes() {
      try {
        setAttributes(await fetchUserAttributes());
        const result = (await fetchAuthSession()).tokens?.idToken;
        setToken(result);
      } catch (error) {
        toast.error("error");
      }
    }
    handleFetchUserAttributes();
  }, [authStatus === 'authenticated']);

  // Token refresh
  useEffect(() => {
    const tokenRefresh = setInterval(async () => {
      const result = (await fetchAuthSession()).tokens?.idToken;
      setToken(result);
    }, 1200000);
    return () => clearInterval(tokenRefresh);
  }, [authStatus === 'authenticated']);

  // saved work
   useEffect(() => {
    const savedWork = setInterval(async () => {
      if (data.title !== "") {
        mutateSave.mutateAsync(data.title);
      }
    }, 1800000);
    return () => clearInterval(savedWork);
  }, [authStatus === 'authenticated']);

  // Getting user data from dynamo db
  useEffect(() => {
    async function handleDynamoUser() {
      try {
        if (token) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/user`, {
            email:  token?.payload.email,
            userId: token?.payload['cognito:username']
            },
            { headers: { "Authorization": token.toString()} }
          )
            setUser(u => ({
              ...u, 
              cap: res.data.body.cap,
              subscription: res.data.body.subscription,
              sign_up_date: res.data.body.sign_up_date,
              works: res.data.body.works
            }));
            setLoading(true);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleDynamoUser();
  }, [token, authStatus === 'authenticated']);

  useEffect(() => {
    async function handleAdminEvents() {
      try {
        if (isAdminUser(token)) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/adminData`,{},
            { headers: { "Authorization": token?.toString()} }
          )
          setSubmissions(res.data.body.submissions);
          setEvents(res.data.body.events);
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleAdminEvents();
  }, [token, authStatus === 'authenticated']);
  
 
  useEffect(() => {
    async function handleCommunityEvents() {
      try {
        if (token) {
          const res = await axios.post(`${process.env.REACT_APP_URL}/communityData`,
            {
              userId: token?.payload['cognito:username']
            },
            { headers: { "Authorization": token.toString()} }
          )
          setEvents(res.data.body.events);
          setUser(u => ({
            ...u, 
            contest_submitted: res.data.body.submission,
          }));
        }
      } catch (error) {
        toast.error("error");
      }
    }
    handleCommunityEvents();
  }, [token, authStatus === 'authenticated']);

  

  interface CacheData {
    title: string;
    M: string;
    T: string;
    G: string;
    CQ: string;
    SUM: string;
    S1: string;
    S2: string;
    S3: string;
    S4: string;
    S5: string;
    S6: string;
    S7: string;
    S8: string;
    S9: string;
  }
  

  
  const CACHE_KEY = recentTitle;
  
  const initialData: CacheData = {  
    title: '',
    M: '',
    T: '',
    G: '',
    CQ: '',
    SUM: '',
    S1: '',
    S2: '',
    S3: '',
    S4: '',
    S5: '',
    S6: '',
    S7: '',
    S8: '',
    S9: '',
  };
  const [data, setData] = useState<CacheData>(initialData);

  const zeroLength = () => {
    return (data.G.length + data.T.length + data.CQ.length + data.SUM.length 
      + data.S1.length + data.S2.length + data.S3.length + data.S4.length
       + data.S5.length + data.S6.length + data.S7.length + data.S8.length
        + data.S9.length);
    }

  // Handle async cache loading
  useEffect(() => {
    const loadCache = async () => {
      try {
        const cachedData = await Cache.getItem(CACHE_KEY);
        if (cachedData) {
          const parsedData = JSON.parse(cachedData);
          setData(parsedData);
        }
      } catch (error) {
        console.error('Error loading cache:', error);
        setData(initialData);
        setRecentTitle("Untitled");
      }
    };

    loadCache();
  }, []); // Empty dependency array means this runs once on mount

  // Create a memoized debounced function
  const debouncedSave = useCallback(
    debounce((newData: CacheData) => {
      try {
        Cache.setItem(CACHE_KEY, JSON.stringify(newData));
      } catch (error) {
        console.error('Error saving to cache:', error);
      }
    }, 500),
    []
  );
  useEffect(() => {
    return () => {
      debouncedSave.cancel();
    };
  }, [debouncedSave]);

  // Create a wrapped signOut function that navigates after signing out
  const handleSignOut = async () => {
    try {
      if (data.title !== "") {
        try {
          await mutateSave.mutateAsync(data.title);
        } catch (saveError) {
          console.error('Error saving before signout:', saveError);
          // Continue with signout even if save fails
        }
      }
      await amplifySignOut();
      navigate('/');
    } catch (error) {
      toast.error("Error signing out");
    }
  };

   //useMutation hook for summary
 const mutateSave = useMutation({
  mutationFn:(title: string) => {
    return save(title);
  },
  onSuccess: (res: any) => {
    if (res.data.statusCode == 200) {
      console.log("Saved!") 
    }
  },
  onError: (error: any) => {
    console.log("error");
  },
});

const save = async(title: string) => {
  return await axios.post(`${process.env.REACT_APP_URL}/works`, {
    "event": "save",
    "title": data.title,
    "userId":token?.payload['cognito:username'],
    "M": data.M,
    "T": data.T,
    "G": data.G,
    "CQ": data.CQ,
    "SUM": data.SUM,
    "S1": data.S1,
    "S2": data.S2,
    "S3": data.S3,
    "S4": data.S4,
    "S5": data.S5,
    "S6": data.S6,
    "S7": data.S7,
    "S8": data.S8,
    "S9": data.S9
  },
  { headers: { "Authorization": token?.toString()} }
  );
}

  return (
    <>
      <Authenticator.Provider>
        <UserContext.Provider value={{
          attributes, 
          user, 
          setUser, 
          signOut: handleSignOut,
          token, 
          setToken, 
          loading, 
          data, 
          setData, 
          debouncedSave, 
          events, 
          setEvents, 
          submissions, 
          setSubmissions,
          zeroLength,
        }}>
          <Routes>
            {/* Public routes */}
            <Route path="/" element={
              <LandingRoute>
                <Landing />
              </LandingRoute>
            } />
            <Route path="/login" element={
              <LoginRoute>
                <Login />
              </LoginRoute>
            } />
            
            {/* Protected routes */}
            <Route path="/home" element={
              <ProtectedRoute>
                <Home attributes={attributes} user={user} setUser={setUser} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/profile" element={
              <ProtectedRoute>
                <Profile attributes={attributes} user={user} setUser={setUser} />
              </ProtectedRoute>
            } />
            <Route path="/prices" element={
              <ProtectedRoute>
                <Prices attributes={attributes} user={user} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/community" element={
              <ProtectedRoute>
                <Community attributes={attributes} user={user} signOut={handleSignOut} />
              </ProtectedRoute>
            } />
            <Route path="/events" element={
              <ProtectedRoute>
                <AdminRoute>
                  <Events />
                </AdminRoute>
              </ProtectedRoute>
            } />
            
            {/* Catch-all route */}
            <Route path='*' element={<NotFound />} />
          </Routes>
        </UserContext.Provider>
      </Authenticator.Provider>
    </>
  );
}

export default App;
