import React from 'react';
import logo from '../background.jpeg'
import { Amplify } from 'aws-amplify';
import Header from './header';
import Footer from './footer';
import { useState ,useContext,useRef,useEffect,useCallback} from "react";
import {useForm} from "react-hook-form";
import { useMutation } from "react-query";
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { Theme, Flex, Text, Button, Grid, Box,Container, Dialog,TextField} from '@radix-ui/themes';
import axios from 'axios';
import Axios from 'axios';
import {UserContext} from '../App';
import '@radix-ui/themes/styles.css';
import FloatingBox from './FloatingBox';
import whiteOverlay from './Head-color with text.png';
import TextArea from './TextArea';
import { Component1Icon } from '@radix-ui/react-icons';
import {TailSpin} from 'react-loading-icons';
import { toast,Toaster } from 'react-hot-toast';
import { User } from '../models/user';
import './home.css';
import InternContainer from './InternContainer';
import { setConstantValue } from 'typescript';
import { throttle } from 'lodash';
Amplify.configure(config);


const GlassesIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M1.5 6.625C1.01675 6.625 0.625 7.01675 0.625 7.5C0.625 7.98325 1.01675 8.375 1.5 8.375C1.98325 8.375 2.375 7.98325 2.375 7.5C2.375 7.01675 1.98325 6.625 1.5 6.625ZM13.5 6.625C13.0168 6.625 12.625 7.01675 12.625 7.5C12.625 7.98325 13.0168 8.375 13.5 8.375C13.9832 8.375 14.375 7.98325 14.375 7.5C14.375 7.01675 13.9832 6.625 13.5 6.625ZM3.125 7.5C3.125 6.2574 4.13235 5.25 5.375 5.25C6.61765 5.25 7.625 6.2574 7.625 7.5C7.625 8.7426 6.61765 9.75 5.375 9.75C4.13235 9.75 3.125 8.7426 3.125 7.5ZM9.625 7.5C9.625 6.2574 10.6324 5.25 11.875 5.25C13.1176 5.25 14.125 6.2574 14.125 7.5C14.125 8.7426 13.1176 9.75 11.875 9.75C10.6324 9.75 9.625 8.7426 9.625 7.5ZM5.375 6C4.5462 6 3.875 6.67125 3.875 7.5C3.875 8.32875 4.5462 9 5.375 9C6.2038 9 6.875 8.32875 6.875 7.5C6.875 6.67125 6.2038 6 5.375 6ZM11.875 6C11.0462 6 10.375 6.67125 10.375 7.5C10.375 8.32875 11.0462 9 11.875 9C12.7038 9 13.375 8.32875 13.375 7.5C13.375 6.67125 12.7038 6 11.875 6ZM1.75 7.5C1.75 7.43788 1.75675 7.37725 1.76925 7.31875L7.31875 5.01925C7.37 5.00675 7.42275 5 7.47575 5H7.52425C7.57725 5 7.63 5.00675 7.68125 5.01925L13.2308 7.31875C13.2433 7.37725 13.25 7.43788 13.25 7.5C13.25 7.56212 13.2433 7.62275 13.2308 7.68125L7.68125 9.98075C7.63 9.99325 7.57725 10 7.52425 10H7.47575C7.42275 10 7.37 9.99325 7.31875 9.98075L1.76925 7.68125C1.75675 7.62275 1.75 7.56212 1.75 7.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/>
  </svg>
);

const SummaryIcon = () => (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M8.69667 0.0403541C8.90859 0.131038 9.03106 0.354857 8.99316 0.582235L8.0902 6.00001H12.5C12.6893 6.00001 12.8625 6.10701 12.9472 6.27641C13.0319 6.4458 13.0136 6.6485 12.8999 6.80001L6.89997 14.8C6.76167 14.9844 6.51521 15.0503 6.30328 14.9597C6.09135 14.869 5.96888 14.6452 6.00678 14.4178L6.90974 9H2.49999C2.31061 9 2.13748 8.893 2.05278 8.72361C1.96809 8.55422 1.98636 8.35151 2.09999 8.2L8.09997 0.200038C8.23828 0.0156255 8.48474 -0.0503301 8.69667 0.0403541ZM3.49999 8.00001H7.49997C7.64695 8.00001 7.78648 8.06467 7.88148 8.17682C7.97648 8.28896 8.01733 8.43723 7.99317 8.5822L7.33027 12.5596L11.5 7.00001H7.49997C7.353 7.00001 7.21347 6.93534 7.11846 6.8232C7.02346 6.71105 6.98261 6.56279 7.00678 6.41781L7.66968 2.44042L3.49999 8.00001Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd" />
  </svg>
);


const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout | null = null;
  return (...args: any[]) => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
};


export function Home(props: any) {

  const {attributes, user, setUser, signOut, token, loading, data, setData,debouncedSave,zeroLength} = useContext(UserContext);
  const [title, setTitle] = useState("");
  const {register, handleSubmit} = useForm();
  const [apiError, setApiError] = useState<string | null>(null);
  const [open, setOpen] = useState(false);
  const [model,setModel] = useState("base")
  const [selectedFields, setSelectedFields] = useState<Set<string>>(new Set());
  const [isInternContainerVisible, setIsInternContainerVisible] = useState(false);
  const allFields = ['G', 'T', 'M', 'CQ', 'SUM', 'S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'S9'];
  const [isSaving, setIsSaving] = useState(false);
  const [showSavedIndicator, setShowSavedIndicator] = useState(false);


  useEffect(() => {
    if (isInternContainerVisible) {
      // When intern window opens, select all fields
      setSelectedFields(new Set(['G', 'T', 'M', 'CQ', 'SUM', 
        'S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'S9']));
    } else {
      // When intern window closes, clear all selections
      setSelectedFields(new Set());
    }
  }, [isInternContainerVisible]);
  
  
  const toggleFieldSelection = (field: string) => {
    if (!isInternContainerVisible) return;
    
    setSelectedFields(prev => {
      const newSet = new Set(prev);
      if (newSet.has(field)) {
        newSet.delete(field);
      } else {
        newSet.add(field);
      }
      return newSet;
    });
  };
  const handleInternToggle = () => {
    setIsInternContainerVisible(!isInternContainerVisible);
  };

  const mainAppWidth = isInternContainerVisible ? '65%' : '100%';

  const handleSelectAll = () => {
    setSelectedFields(new Set(['G', 'T', 'M', 'CQ', 'SUM', 'S1', 'S2', 'S3', 'S4', 'S5', 'S6', 'S7', 'S8', 'S9']));
  };
  
  const handleDeselectAll = () => {
    setSelectedFields(new Set());

    
  };

  const mainContentRef = useRef<HTMLDivElement>(null);
  

  
  //wrapper
  const [wrapperTopPosition, setWrapperTopPosition] = useState(0);
  const wrapperRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const updateWrapperPosition = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setWrapperTopPosition(rect.top + window.scrollY);
      }
    };

    updateWrapperPosition();
    window.addEventListener('resize', updateWrapperPosition);
    return () => window.removeEventListener('resize', updateWrapperPosition);
  }, []);

  //scroller
  const [expanded, setExpanded] = useState(false);
  const [hasScrolled, setHasScrolled] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const [maxScrollPosition, setMaxScrollPosition] = useState(0);

  const handleScroll = useCallback(
    throttle(() => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const newScrollPosition = Math.max(0, window.scrollY - rect.top);
        setHasScrolled(window.scrollY > rect.top);
        setScrollPosition(newScrollPosition);
      }
    }, 100), // Adjust the delay as needed
    []
  );

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [handleScroll]);

  //look into later. dont know what this does
  const [wrapperWidth, setWrapperWidth] = useState(0);
  const [drawerExpanded, setDrawerExpanded] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        const newScrollPosition = Math.max(0, window.scrollY - rect.top);
        setHasScrolled(window.scrollY > rect.top);
        setScrollPosition(newScrollPosition);

        // Calculate the maximum scroll position with 15% buffer
        const parentHeight = wrapperRef.current.clientHeight;
        const floatingBoxHeight = 200; // 200px
        const bufferHeight = parentHeight * 0.15;
        const newMaxScrollPosition = parentHeight - floatingBoxHeight - bufferHeight;
        setMaxScrollPosition(newMaxScrollPosition);
      }
    };

    const updateWrapperDimensions = () => {
      if (wrapperRef.current) {
        const rect = wrapperRef.current.getBoundingClientRect();
        setWrapperWidth(rect.width);
      }
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', updateWrapperDimensions);

    // Initial call to set dimensions and scroll position
    updateWrapperDimensions();
    handleScroll();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', updateWrapperDimensions);
    };
  }, []);

  const toggleDrawer = () => {
    setDrawerExpanded(!drawerExpanded);
  };


  const [customLabels, setCustomLabels] = useState({
    SUM: 'Summary',
    S1: 'Introduction and Stasis',
    S2: 'Inciting Incident',
    S3: 'Comittment',
    S4: 'First Pinch Point',
    S5: 'Midpoint',
    S6: 'Second Pinch Point',
    S7: 'Second Plot Point',
    S8: 'Climax',
    S9: 'Resolution'
  });


  const handleDebouncedSave = useCallback((newData: any) => {
    setIsSaving(true);
    return new Promise((resolve, reject) => {
      try {
        const result = debouncedSave(newData);
        // If debouncedSave returns a promise, use it
        if (result && typeof result.then === 'function') {
          result
            .then(() => {
              setIsSaving(false);
              // Wait 2 seconds after save completes
              setTimeout(() => {
                setShowSavedIndicator(true);
                // Show for 1 second
                setTimeout(() => {
                  setShowSavedIndicator(false);
                }, 1000);
              }, 1000);
              resolve(result);
            })
            .catch((error: any) => {
              setIsSaving(false);
              console.error('Save error:', error);
              toast.error("Error saving");
              reject(error);
            });
        } else {
          // If debouncedSave doesn't return a promise, resolve immediately
          setIsSaving(false);
          // Wait 2 seconds after save completes
          setTimeout(() => {
            setShowSavedIndicator(true);
            // Show for 1 second
            setTimeout(() => {
              setShowSavedIndicator(false);
            }, 1000);
          }, 1000);
          resolve(result);
        }
      } catch (error) {
        setIsSaving(false);
        console.error('Save error:', error);
        toast.error("Error saving");
        reject(error);
      }
    });
  }, [debouncedSave]);

  const clearField = (segmentId: string) => {
    const newData = {
      ...data,
      [segmentId]: ""
    };
    setData(newData);
    return handleDebouncedSave(newData);
  };

  const clearAllFields = () => {
    const newData = {
      ...data,
      M: "", T: "", G: "", CQ: "", SUM: "",
      S1: "", S2: "", S3: "", S4: "", S5: "",
      S6: "", S7: "", S8: "", S9: ""
    };
    setData(newData);
    return handleDebouncedSave(newData);
  };

  const toggleModel = (newModel: string) => {
    setModel(newModel);
  };

  const randomGenre = () => {
      const genres = ['Drama/Comedy', 'Drama/Thriller', 'Drama/War', 'Drama/Mystery', 'Drama/Arthouse'];
      const randomGenre = genres[Math.floor(Math.random() * genres.length)];
      return randomGenre;
  };

  const randomSetting = () =>  {
    const settings = [
    "Present-day New York City, in a midtown office building",
    "Small coastal town in Maine, recovering from a recent storm",
    "Present-day rural Appalachia, in an isolated mountain community",
    "Suburban neighborhood in Ohio, centered around two adjacent homes",
    "Modern-day Detroit, in a working-class neighborhood with abandoned factories",
    "A small town in the Midwest, centered around a local diner",
    "Tight quarters beneath the glittering exterior of a luxury cruise ship",
    "Present-day Los Angeles, on the traffic-filled freeways",
    "Present-day Chicago, in a bustling downtown district",
    "A remote ranch in present-day Texas, miles from the nearest town",
    "A tech startup office in Silicon Valley",
    "Present-day New Orleans, in a historic neighborhood near the French Quarter",
    "A suburban high school in present-day Colorado",
    "Present-day Atlanta, within a busy airport terminal",
    "Present-day Portland, Oregon, in a hip, gentrifying neighborhood",
    "A small town in rural Portugal, surrounded by wind turbines and modern agriculture",
    "A rural fishing village in present-day Iceland",
    "Present-day Tokyo, in a bustling corporate office building",
    "Modern-day Mexico City, within a vibrant street market",
    "1990s Bologna, Italy, amidst a changing cultural and political landscape",
    "Early 2000s Brazil, in a sprawling urban favela",
    "Victorian-era London, amidst the fog and cobblestone streets",
    "Late 19th-century Paris, in the artist quarter of Montmartre",
    "Near-alternate future wasteland, post-American new civil war",
    "Cold War-era Berlin, near the Berlin Wall"
  ];
  const randomSetting = settings[Math.floor(Math.random() * settings.length)];
  return randomSetting;
};

  
  //handler for summary submit
  const handleSummary = (e: any) => {
    const formData = new FormData(e.target);
    clearField('SUM');

    mutateSummary.mutate(formData);
  }
  //useMutation hook for summary
  const mutateSummary = useMutation({
    mutationFn:(formData: any) => {
      return summary(formData);
    },
    onSuccess: (res: any) => {
      if (res.data.statusCode == 200) {
        const newData = {
          ...data,
          SUM: res.data.body.SUM 
        };
        setData(newData);
        handleDebouncedSave(newData)
          .catch((error: any) => {
            console.error('Error creating summary:', error);
            toast.error("Error creating summary");
          });
        setUser((user: User) => ({...user, cap: res.data.body.cap}));
      } else if (res.data.statusCode == 400) {
        toast.error(res.data.body.error);
      } else {
        toast.error("error");
      }
    },
    onError: (error: any) => {
      toast.error("error");
    },
  });
  const summary = async (formData: any) => {
    let genre = data.G;
    if (genre.length == 0) {
      genre = randomGenre();
    }
    let setting = data.M;
    if (setting.length == 0) {
      setting = randomSetting()
    }
    return await axios.post(`${process.env.REACT_APP_URL}/summary`,
      {
          "event": "summary",
          "content": model,
          "userId": token?.payload['cognito:username'],
          "M": setting,
          "T": data.T,
          "G": genre,
          "CQ": data.CQ,
      },
      { headers: { "Authorization": token.toString()}}
      );
  } 
  //handler for story submit
  const handleStory = (e: any) => {
    if (zeroLength() == 0) {
      toast.error("Please fill out a field below");
    } else {
      const formData = new FormData(e.target);
      mutateStory.mutate(formData);
    }
  }
 

  //useMutation hook for story
  const mutateStory = useMutation({
    mutationFn:(formData: any) => {
      return story(formData);
    },
    onSuccess: (res: any) => {
      if (res.data.statusCode == 200) {
        setUser((user: User) => ({...user, cap: res.data.body.cap}));
        const newData = {
          ...data,
          M: res.data.body.output.M,
          T: res.data.body.output.T,
          G: res.data.body.output.G,
          CQ: res.data.body.output.CQ,
          SUM: res.data.body.output.SUM,
          S1: res.data.body.output.S1,
          S2: res.data.body.output.S2,
          S3: res.data.body.output.S3,
          S4: res.data.body.output.S4,
          S5: res.data.body.output.S5,
          S6: res.data.body.output.S6,
          S7: res.data.body.output.S7,
          S8: res.data.body.output.S8,
          S9: res.data.body.output.S9
        };
        setData(newData);
        handleDebouncedSave(newData)
          .catch((error: any) => {
            console.error('Error creating story:', error);
            toast.error("Error creating story");
          });
        setExpanded(true);
      } else if (res.data.statusCode == 400) {
        toast.error(res.data.body.error);
      } else {
        toast.error("error");
      }
    },
    onError: (error: any) => {
      toast.error("error");
    },
  });

  const story = async(formData: any) => {
    return await axios.post(`${process.env.REACT_APP_URL}/summary`, {
          "event": "story",
          "content": model,
          "userId": token?.payload['cognito:username'],
          "M": data.M,
          "T": data.T,
          "G": data.G,
          "CQ": data.CQ,
          "SUM": data.SUM,
          "S1": data.S1,
          "S2": data.S2,
          "S3": data.S3,
          "S4": data.S4,
          "S5": data.S5,
          "S6": data.S6,
          "S7": data.S7,
          "S8": data.S8,
          "S9": data.S9
      },
      { headers: { "Authorization": token.toString()} }
    );
    }
  const handleSave = (e: any, title: string) => {
    if (title.length == 0) {
      toast.error("Please enter a title");
    } else if (title == data.title){
      toast.error("Please enter a new title");
    } else if (data.title !== ""){
      mutateSave.mutate(data.title);
      mutateSave.mutate(title);
    } else {
      mutateSave.mutate(title);
    }
  }

  //useMutation hook for summary
  const mutateSave = useMutation({
    mutationFn:(title: string) => {
      setOpen(false);
      return save(title);
    },
    onSuccess: (res: any) => {
      if (res.data.statusCode == 200) {
        if (data.title == "") {
          toast.success("story saved!");

        }
        const newData = {
          ...data,
          title: title
        };
        setData(newData);
        handleDebouncedSave(newData)
          .catch((error: any) => {
            console.error('Error saving story:', error);
            toast.error("Error saving story");
          });
        setUser((user: User) => ({...user, works: res.data.body.works}));
        setTitle("");
      } else if (res.data.statusCode == 400) {
        toast.error(res.data.body.error);
        console.log(res.data);
        setTitle("");
      } else {
        toast.error("error");
        console.log(res.data);
        setTitle("");
      }
    },
    onError: (error: any) => {
      console.log(error);
      toast.error("error");
      setTitle("");
    },
  });

  const save = async(title: string) => {
    return await axios.post(`${process.env.REACT_APP_URL}/works`, {
      "event": "save",
      "title": title,
      "userId":token?.payload['cognito:username'],
      "M": data.M,
      "T": data.T,
      "G": data.G,
      "CQ": data.CQ,
      "SUM": data.SUM,
      "S1": data.S1,
      "S2": data.S2,
      "S3": data.S3,
      "S4": data.S4,
      "S5": data.S5,
      "S6": data.S6,
      "S7": data.S7,
      "S8": data.S8,
      "S9": data.S9
    },
    { headers: { "Authorization": token.toString()} }
    );
  }
  const handleChange = (field: any, isInternField: boolean) => (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    if (!isInternContainerVisible || isInternField) {
      const newData = {
        ...data,
        [field]: event.target.value
      };
      setData(newData);
      handleDebouncedSave(newData)
        .catch(error => {
          console.error('Error in handleChange:', error);
        });
    }
  }

  const renderTextArea = (field: string, rows: number = 1, width: string = '100%', isInternField: boolean = false) => (
      <TextArea
        field={field}
        rows={rows}
        width={width}
        value={data[field as keyof typeof data]}
        onChange={handleChange(field,isInternField)} 
        onClearField={clearField}
        customLabels={customLabels}
        isHighlighted={selectedFields.has(field)}
        onToggleHighlight={toggleFieldSelection}
        isHighlightable={isInternContainerVisible && !isInternField}
        isDisabled={isInternContainerVisible && !isInternField}
        isInternField={isInternField}
      />
    );
      
  if (loading == false) {
    return (
      <>
        <div
        style={{
          position: 'relative',
          left:'50%',
          top:'300px',
        }}   
        >
      <TailSpin  stroke="#FFA500" speed="1.3" />
        </div>
      </>
    );
  }
  else {
    return (
    <>
      <Theme>
      <Toaster
      position="top-center"
      reverseOrder={false}
      />
      <Header/>
      <div className="app-container" style={{ paddingBottom: '3rem' }}>
      <div className="gradient-background"></div>
        
        <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
        {/*< div className= "banner-container">
        <div className= "orange-shape"></div>
        <img src={whiteOverlay} alt="White overlay" className = "white-overlay"/>
       </div> */}
        <div
          style={{
            color: 'white',
            position: 'absolute',
            left: '80%',
            top: '-1rem',
            transform: 'translateX(-50%)',
            zIndex: 3
          }}
        > 
          <Component1Icon />
          {user?.cap}
        </div>        {/* Saved Indicator */}

        {data.title && (
                  <Text 
                    size="5" 
                    weight="bold" 
                    style={{
                      color: 'white',
                      textShadow: '0 2px 4px rgba(0,0,0,0.2)',
                      marginBottom: '2rem',
                    }}
                  >
                    {data.title}
                  </Text>
                )}
        <Box
            style={{
              position: 'fixed',
              top: '20px',
              right: '20px',
              zIndex: 1000,
              padding: '8px 16px',
              borderRadius: '4px',
              backgroundColor: 'rgba(0, 0, 0, 0.7)',
              color: 'white',
              transition: 'opacity 0.3s ease',
              opacity: showSavedIndicator ? 1 : 0,
              pointerEvents: 'none',
            }}
          >
            {isSaving ? 'Saving...' : 'Saved'}
          </Box>
        <Flex style={{ 
            width: '100%', 
            justifyContent: 'center', 
            position: 'relative', 
            paddingTop: '3rem' // 50px 
          }}>
            <Flex 
            style={{ 
              justifyContent: 'center',
              position: 'relative', 
              width: isInternContainerVisible ? '120%' : '90%',
              maxWidth: isInternContainerVisible ? '75rem' : '75rem',
              transition: 'all 0.3s ease-in-out'
            }}>
            <Flex direction="column" width="90%" maxWidth="75rem" align="center" className="text-areas-background" ref={wrapperRef}>
            
            <Box style={{
              position: 'relative',
              bottom: '1.25rem', // 20px
              backgroundColor: 'rgba(255, 255, 255, 0.2)',
              borderRadius: '1.5rem', // 24px
              padding: '2rem', // 32px
              paddingTop: '2.5rem', // 40px
              backdropFilter: 'blur(0.625rem)', // 10px
              boxShadow: '0 0.75rem 1.5rem rgba(0, 0, 0, 0.2)', // 12px 24px
              border: '0.0625rem solid rgba(255, 255, 255, 0.3)', // 1px
              width: '100%',
              margin: '1.5625rem 0', // 25px
              zIndex: 2,
              display: 'flex',
              justifyContent: 'space-between',
              transition: 'all 0.3s ease-in-out',
            }}>
              <Box style={{
                position: 'absolute',
                top: -'4.2rem',
                left: 0,
                right: 0,            
                zIndex: 3,
                height: '4rem', // 40px
                overflow: 'visible',
                justifyContent: 'center',
              
              }}>
                <FloatingBox 
                  expanded={drawerExpanded} 
                  onClearAllFields={clearAllFields}
                  onToggle={toggleDrawer}
                  setNewModel={(model: string) => toggleModel(model)}
                  isScrolled={hasScrolled && expanded}
                  wrapperWidth={wrapperWidth}
                  scrollPosition={scrollPosition}
                  maxScrollPosition={maxScrollPosition}
                  onInternToggle={handleInternToggle}
                  isInternActive={isInternContainerVisible}
                />                
              </Box>
              <Flex 
                direction="column" 
                style={{
                  width: isInternContainerVisible ? '65%' : '100%',
                  transition: 'all 0.3s ease-in-out',
                  position: 'relative', 
                  zIndex: 2,
                  marginTop: '-0.625rem', // -10px
                }}
              >
                <form>
                  <Container size="3" align="center">
                    <Box className="white-container">
                      <Flex direction="column" align="center" style={{ width: '100%' }}>
                        <Grid columns="2" justify="center" gap="4"
                          style={{
                            margin: '2rem', // Center grid in parent
                            maxWidth: '120%', // Restrict grid width
                             // Avoid overflow
                          }}
                        >
                          {renderTextArea('G', 2, '100%')}
                          {renderTextArea('T', 3, '140%')}
                          {renderTextArea('M', 3, '100%')}
                          {renderTextArea('CQ', 3, '140%')}
                        </Grid>
                      </Flex>
                    </Box>
                  </Container>
                  <Container size="3" align="center">
                    <Box className="white-container">
                      {renderTextArea('SUM', 3, '100%')}
                        <Button 
                          onClick={(e) => {handleSubmit(handleSummary)(e)}} 
                          type="submit" 
                          name="generate_summary" 
                          variant="solid" 
                          className="semi-transparent-button"
                          disabled={mutateSummary.isLoading}
                        >
                        <SummaryIcon />
                        {mutateSummary.isLoading ? 'Generating...' : 'Generate Summary'}
                        </Button>
                    </Box>
                    </Container>
                    <Container size="4" align="center" style={{ height: 'auto' }}>
                        <Box className="wider-white-container" style={{ 
                          height: 'auto', 
                          minHeight: '200px',
                          transition: 'all 0.2s ease-in-out'
                        }}>
                          {!expanded && (
                            <Flex justify="center" py="9">
                              <Button type="button" variant="solid" radius="full" className="semi-transparent-button" onClick={() => setExpanded(true)}>
                                <svg
                                  width="1.875rem" // 30px
                                  height="1.875rem" // 30px
                                  viewBox="-2 0 15 15"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                  style={{ marginRight: '0.5rem' }} // 8px
                                >
                                  <path
                                    d="M8 2.75C8 2.47386 7.77614 2.25 7.5 2.25C7.22386 2.25 7 2.47386 7 2.75V7H2.75C2.47386 7 2.25 7.22386 2.25 7.5C2.25 7.77614 2.47386 8 2.75 8H7V12.25C7 12.5261 7.22386 12.75 7.5 12.75C7.77614 12.75 8 12.5261 8 12.25V8H12.25C12.5261 8 12.75 7.77614 12.75 7.5C12.75 7.22386 12.5261 7 12.25 7H8V2.75Z"
                                    fill="currentColor"
                                    fillRule="evenodd"
                                    clipRule="evenodd"
                                  />
                                </svg>
                                Expand Story Segments
                              </Button>
                            </Flex>
                          )}
                          {expanded && (
                            <>
                              {Array.from({ length: 9 }, (_, i) => i + 1).map((i) => renderTextArea(`S${i}`, 5, '100%'))}
                            </>
                          )}
                        </Box>
                      </Container>
                      <Flex justify="between" align="center" >
                        <Dialog.Root open={open} onOpenChange={setOpen}>
                          <Dialog.Trigger>
                          <Button
                              name="save_work"
                              style={{
                                background: '#ffffff',
                                color: 'black',
                                padding: '0.75rem 2rem',
                                borderRadius: '0.5rem',
                                border: 'none',
                                fontWeight: '500',
                                transition: 'all 0.2s ease',
                                boxShadow: '0 4px 12px rgba(255, 140, 0, 0.2)',
                                cursor: 'pointer',
                                display: 'flex',
                                alignItems: 'center',
                                gap: '0.5rem'
                              }}
                              onMouseEnter={e => {
                                e.currentTarget.style.transform = 'translateY(-2px)';
                                e.currentTarget.style.boxShadow = '0 6px 16px rgba(255, 140, 0, 0.25)';
                              }}
                              onMouseLeave={e => {
                                e.currentTarget.style.transform = 'translateY(0)';
                                e.currentTarget.style.boxShadow = '0 4px 12px rgba(255, 140, 0, 0.2)';
                              }}
                            >
                              <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M3.5 2C3.22386 2 3 2.22386 3 2.5V12.5C3 12.7761 3.22386 13 3.5 13H11.5C11.7761 13 12 12.7761 12 12.5V4.70711L9.29289 2H3.5ZM2 2.5C2 1.67157 2.67157 1 3.5 1H9.5C9.63261 1 9.75979 1.05268 9.85355 1.14645L12.8536 4.14645C12.9473 4.24021 13 4.36739 13 4.5V12.5C13 13.3284 12.3284 14 11.5 14H3.5C2.67157 14 2 13.3284 2 12.5V2.5ZM4.75 7.5C4.75 7.22386 4.97386 7 5.25 7H9.75C10.0261 7 10.25 7.22386 10.25 7.5C10.25 7.77614 10.0261 8 9.75 8H5.25C4.97386 8 4.75 7.77614 4.75 7.5ZM4.75 9.5C4.75 9.22386 4.97386 9 5.25 9H9.75C10.0261 9 10.25 9.22386 10.25 9.5C10.25 9.77614 10.0261 10 9.75 10H5.25C4.97386 10 4.75 9.77614 4.75 9.5Z" fill="currentColor" fillRule="evenodd" clipRule="evenodd"/>
                              </svg>
                              Save Work
                            </Button>
                          </Dialog.Trigger>
                          <Dialog.Content maxWidth="450px">
                            <Dialog.Title>Save Work</Dialog.Title>
                            <Dialog.Description size="2" mb="4">
                            {data.title !== "" ? 
                            "Save new work (current work will be saved as well)" : 
                            "Save your work."}  
                            </Dialog.Description>

                            <Flex direction="column" gap="3">
                              <label>
                                <Text as="div" size="2" mb="1" weight="bold">
                                  Title
                                </Text>
                                <TextField.Root
                                  placeholder="Enter a title"
                                  value={title}
                                  onChange={(e) =>  {
                                    setTitle(e.target.value)
                                  }}
                                />
                              </label>
                            </Flex>
                            <Flex gap="3" mt="4" justify="end">
                              <Dialog.Close>
                                <Button variant="soft">
                                  Cancel
                                </Button>
                              </Dialog.Close>
                              <Button 
                              onClick={(e) => {
                                e.preventDefault();
                                handleSave(e, title);
                              }}
                              disabled={!title}
                              >
                                Save
                              </Button>
                            </Flex>
                          </Dialog.Content>
                        </Dialog.Root>   
                      
                      <Button 
                        onClick={(e) => {handleSubmit(handleStory)(e)}} 
                        name="save_work"
                        style={{
                          background: '#ffffff',
                          color: 'black',
                          padding: '0.75rem 1rem',
                          borderRadius: '0.5rem',
                          border: 'none',
                          fontWeight: '500',
                          transition: 'all 0.2s ease',
                          boxShadow: '0 4px 12px rgba(255, 140, 0, 0.2)',
                          cursor: 'pointer',
                          display: 'flex',
                          alignItems: 'center',
                          gap: '0.5rem'
                        }}
                        onMouseEnter={e => {
                          e.currentTarget.style.transform = 'translateY(-2px)';
                          e.currentTarget.style.boxShadow = '0 6px 16px rgba(255, 140, 0, 0.25)';
                        }}
                        onMouseLeave={e => {
                          e.currentTarget.style.transform = 'translateY(0)';
                          e.currentTarget.style.boxShadow = '0 4px 12px rgba(255, 140, 0, 0.2)';
                        }}
                      
                        disabled={mutateStory.isLoading}
                      >
                        <SummaryIcon />
                       {mutateStory.isLoading ? 'Generating...' : 'Generate Story'}
                      </Button> 
                      </Flex>
                      </form>
                      </Flex>
                      {isInternContainerVisible && (
                  <Box
                    style={{
                      position: 'relative',
                      width: '33%',
                      marginLeft: '2%',
                      height: '100%',
                    }}
                  >
                    <InternContainer 
                      isVisible={isInternContainerVisible} 
                      storyData={data}
                      onStoryUpdate={(newData) => {
                        setData(newData);
                        handleDebouncedSave(newData)
                          .catch((error: any) => {
                            console.error('Error saving intern update:', error);
                            toast.error("Error saving changes");
                          });
                      }}
                      onDeselectAll={handleDeselectAll}
                      onSelectAll={handleSelectAll}
                      selectedFields={selectedFields}
                      mainContentRef={mainContentRef} 
                    />
                  </Box>
                )}
                    </Box>
                  </Flex>
               </Flex> 
               </Flex> 
              </Flex>
            </div>
            <Footer/>
          </Theme>
        </>
      )};
}
  export default Home;