import React, { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import './landing.css';
import LandingTitle from './FA New Test.png';
import YourImage from './New-Treatment.png';
import BackgroundWide from './Wide-with-head-3.mp4';
import FloatingCTA from './FloatingCTA';
import PricingSection from './PricingSection';


// Import feature videos
import FeatureVideo1 from './Summary Insert.mp4';
import FeatureVideo2 from './Sequence-Back.mp4';
import FeatureVideo3 from './Intern Graphics_2.mp4';
import FeatureVideo4 from './W-effect.mp4';

const Landing: React.FC = () => {
  const navigate = useNavigate();
  const videoRef = useRef<HTMLVideoElement>(null);
  const pricingSectionRef = useRef<HTMLElement>(null);

  const scrollToPricing = () => {
    pricingSectionRef.current?.scrollIntoView({ 
      behavior: 'smooth',
      block: 'start'
    });
  };

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.play().catch(() => {});
    }
  }, []);

  const features = [
    <>
      See your concepts <span style={{ textDecoration: 'underline' }}>laid out and expertly outlined</span> in seconds
    </>,
    <>
      Easily make <span style={{ textDecoration: 'underline' }}>small iterations or significant story overhauls</span>
    </>,
    <>
      <span style={{ textDecoration: 'underline' }}>Get endless inspiration</span> and take the first step into your next story
    </>,
    <>Participate in the growing community</>,
    <>
      What you create is <span style={{ textDecoration: 'underline', fontStyle: 'italic' }}>always yours</span>
    </>,
  ];
  

  type Feature =
    | {
        title: string;
        description: string;
        video: string;
        alignment: 'left' | 'right';
      }
    | {
        title: string;
        description: string;
        alignment: 'center';
      };

  const thirdSectionFeatures: Feature[] = [
    {
      title: 'Need a Spark? Test Endless Ideas',
      description:
        'Generate Strong, Detailed Story Summaries with models fine-tuned on handcrafted training data to spark your next vision.',
      video: FeatureVideo1,
      alignment: 'left',
    },
    {
      title: 'Pieces of an Idea? Build it out from any direction',
      description:
        'Our models will fill in around any parts of your idea. Build around a shift at the midpoint, Start with your killer ending. The options are infinite.',
      video: FeatureVideo2,
      alignment: 'right',
    },
    {
      title: 'Keeping Building',
      description:
        'Use the Intern to guide further generation and build further upon your outline and what the models generate with more precise guidance.',
      video: FeatureVideo3,
      alignment: 'left',
    },
    {
      title: 'Win Prizes',
      description: 'Join the growing community and participate in our regular contests for CASH PRIZES.',
      video: FeatureVideo4,
      alignment: 'right',
    },
    {
      title: 'Much, Much More on the Horizon',
      description:
        'There are numerous features in the works as we are committed to building this into the best AI Screenwriting Studio for writers.',
      alignment: 'center',
    },
  ];

  const FeatureVideo = ({ src, index }: { src: string; index: number }) => {
    const videoRef = useRef<HTMLVideoElement>(null);
    const [isHovered, setIsHovered] = useState(false);
  
    useEffect(() => {
      const video = videoRef.current;
      if (video) {
        if (isHovered) {
          try {
            video.play();
          } catch (error) {
            // Handle play error silently
          }
        } else {
          video.pause();
          video.currentTime = 0;
        }
      }
    }, [isHovered]);
  
    const PlayIcon = () => (
      <svg 
        width="48" 
        height="48" 
        viewBox="0 0 24 24" 
        fill="white"
        stroke="none"
      >
        <polygon points="6 4 18 12 6 20 6 4" /> // Moved points inward slightly
      </svg>
    );
  
    return (
      <div 
        className={`feature-video-container ${
          index % 2 === 0 ? 'tilt-left' : 'tilt-right'
        }`}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        {!isHovered && (
          <div className="video-thumbnail-overlay">
            <div className="play-button">
              <PlayIcon />
            </div>
          </div>
        )}
        <video
          ref={videoRef}
          src={src}
          loop
          muted
          playsInline
          preload="auto"
          className="feature-video"
        />
      </div>
    );
  };

  return (
    <div className="landing-page">
      <section className="hero-container">
        <div className="video-background">
          <video
            ref={videoRef}
            autoPlay
            loop
            muted
            playsInline
            className="background-video"
          >
            <source src={BackgroundWide} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </div>

        <div className="black-overlay" />

        <div className="content-wrapper">
          <section className="hero-section">
            <div className="hero-content">
              <img src={LandingTitle} alt="FilmAssistant.io" className="hero-logo" />

              <div className="hero-text-group">
                <p className="hero-text">
                  Need a spark or have a script that needs finishing touches? Build out your story from any
                  point—forward, backward, or anywhere in between. Save time on outlining and focus on making
                  it your story, unique and original.
                </p>

                <div className="hero-features-list">
                  {features.map((feature, index) => (
                    <div key={index} className="hero-feature-item">
                      {feature}
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className="hero-cta-wrapper">
              <div className="hero-cta">
                <div className="btn-overlay-container">
                  <button onClick={() => navigate('/login')} className="btn btn-primary">
                    <span className="btn-text">Join the Beta</span>
                  </button>
                  <video autoPlay loop muted playsInline className="btn-overlay">
                    <source src="/35mm_G3_DIRTY_2s.mp4" type="video/mp4" />
                  </video>
                </div>
                <button onClick={scrollToPricing} className="btn btn-secondary">
                  See Pricing
                </button>
              </div>
              
            </div>
          </section>
        </div>
      </section>

      <section className="image-section">
        <div className="image-container">
          <div className="image-wrapper">
            <img src={YourImage} alt="Descriptive Alt Text" className="centered-image" />
          </div>
          <div className="text-box">
            The Growing AI Screenwriting Software for All Writers
          </div>
        </div>
        <div className="image-section-shadow"></div>
      </section>

      <section className="third-section">
        <div className="third-section-content">
          <h2>Dive In!</h2>
          <div className="feature-video-pairs">
            {thirdSectionFeatures.map((feature, index) => (
              <div key={index} className="feature-video-pair">
                {feature.alignment === 'left' ? (
                  <>
                    <div className="feature-box">
                      <h3>{feature.title}</h3>
                      <p>{feature.description}</p>
                    </div>
                    {'video' in feature && (
                      <FeatureVideo src={feature.video} index={index} />
                    )}
                  </>
                ) : feature.alignment === 'right' ? (
                  <>
                    {'video' in feature && (
                      <FeatureVideo src={feature.video} index={index} />
                    )}
                    <div className="feature-box">
                      <h3>{feature.title}</h3>
                      <p>{feature.description}</p>
                    </div>
                  </>
                ) : (
                  <div className="feature-box feature-box-center">
                    <h3>{feature.title}</h3>
                    <p>{feature.description}</p>
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>

      <section className="additional-section">
      <PricingSection ref={pricingSectionRef} />
      </section>
  
      <FloatingCTA/>
    </div>
  );
};

export default Landing;