import React from 'react';
import { Theme, Flex } from '@radix-ui/themes';
import { Check, Settings } from 'lucide-react';
import { TailSpin } from 'react-loading-icons';
import Header from './header';
import Footer from './footer';
import whiteOverlay from './Head-color with text.png';
import { toast, Toaster } from 'react-hot-toast';
import { useContext } from 'react';
import { UserContext } from '../App';
import axios from 'axios';
import { useMutation } from 'react-query';
import { Amplify } from 'aws-amplify';
import config from '../aws-exports';
import './prices.css';

Amplify.configure(config);

interface PricesProps {
  [key: string]: any;
}

interface PriceCardProps {
  title: string;
  amount: string;
  period: string;
  features: string[];
  buttonText: string;
  onClick: () => void;
  disabled?: boolean;
  isPopular?: boolean;
}

const PriceCard: React.FC<PriceCardProps> = ({
  title,
  amount,
  period,
  features,
  buttonText,
  onClick,
  disabled = false,
  isPopular = false
}) => {
  return (
    <div className={`card-wrapper ${isPopular ? 'popular' : ''}`}>
      {isPopular && <div className="popular-tag">Most Popular</div>}
      <div>
        <h2 className="card-title">{title}</h2>
        <div className="price-container">
          <span className="price-amount">{amount}</span>
          <span className="price-period">{period}</span>
        </div>
      </div>
      
      <ul className="feature-list">
        {features.map((feature, index) => (
          <li key={index} className="feature-item">
            <Check size={20} />
            <span>{feature}</span>
          </li>
        ))}
      </ul>

      <button
        className="pricing-button"
        onClick={onClick}
        disabled={disabled}
      >
        {buttonText}
      </button>
    </div>
  );
};

export function Prices(props: PricesProps) {
  const { user, token, loading } = useContext(UserContext);

  const subscribe = useMutation({
    mutationFn: () => member(),
    onSuccess: (res: any) => {
      window.open(res.data.headers.Location, "_self");
    },
    onError: () => {
      toast.error("error");
    },
  });

  const refill = useMutation({
    mutationFn: () => base(),
    onSuccess: (res: any) => {
      window.open(res.data.headers.Location, "_self");
    },
    onError: () => {
      toast.error("error");
    },
  });

  const refill_discount = useMutation({
    mutationFn: () => discount(),
    onSuccess: (res: any) => {
      window.open(res.data.headers.Location, "_self");
    },
    onError: () => {
      toast.error("error");
    },
  });

  const member = async () => {
    return await axios.post(
      `${process.env.REACT_APP_URL}/checkout`,
      {
        "event": "member",
        "email": token?.payload.email,
        "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token?.toString() } }
    );
  };

  const base = async () => {
    return await axios.post(
      `${process.env.REACT_APP_URL}/checkout`,
      {
        "event": "base",
        "email": token?.payload.email,
        "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token?.toString() } }
    );
  };

  const discount = async () => {
    return await axios.post(
      `${process.env.REACT_APP_URL}/checkout`,
      {
        "event": "discount",
        "email": token?.payload.email,
        "userId": token?.payload['cognito:username']
      },
      { headers: { "Authorization": token?.toString() } }
    );
  };

  if (!loading) {
    return (
      <div style={{ position: 'relative', left: '50%', top: '18.75rem' }}>
        <TailSpin stroke="#FFA500" speed="1.3" />
      </div>
    );
  }

  const pricingPlans = [
    {
      title: "Member",
      amount: "$6.00",
      period: "/month",
      features: [
        "~75 generations per month",
        "Access to Community",
        "Participate in Weekly Contests",
        "Better Rate on Tokens",
        "Cheaper Token Refills"
      ],
      buttonText: user?.subscription === "member" ? "Current Plan" : "Buy Plan",
      onClick: () => subscribe.mutateAsync().catch(() => toast.error("error")),
      disabled: user?.subscription === "member",
      isPopular: true
    },
    {
      title: "Base Token Package",
      amount: "$8.00",
      period: "one-time",
      features: [
        "~75 generations",
        "Create more stories",
        "Create more summaries",
        "One time purchase"
      ],
      buttonText: "Buy Tokens",
      onClick: () => refill.mutateAsync().catch(() => toast.error("error"))
    },
    {
      title: "Member Token Refill",
      amount: "$3.00",
      period: "one-time",
      features: [
        "~75 more generations",
        "Member discount applied",
        "One time purchase",
        "Member access only"
      ],
      buttonText: "Member Access Only",
      onClick: () => {
        if (user?.subscription === "member") {
          refill_discount.mutateAsync().catch(() => toast.error("error"));
        }
      },
      disabled: user?.subscription !== "member"
    }
  ];

  return (
    <Theme>
      <Toaster position="top-center" reverseOrder={false} />
      <div className="gradient-background" />
      <Header />

      {user?.subscription === "member" && (
        <button
          className="manage-subscription-button"
          onClick={() => {
            const billingUrl = process.env.REACT_APP_STRIPE_PORTAL_URL;
            window.open(billingUrl, "_self");
          }}
        >
          <Settings size={16} />
          Manage Subscription
        </button>
      )}
      <div>
      <Flex direction="column" justify="center" align="center" style={{ flexGrow: 1, position: 'relative' }}>
          <h1 className="page-header">Choose Your Plan</h1>
        </Flex>
        <Flex justify="center" gap="8" wrap="wrap">
          {pricingPlans.map((plan, index) => (
            <PriceCard key={index} {...plan} />
          ))}
        </Flex>
      </div>

    <Footer />
    </Theme>
  );
}

export default Prices;