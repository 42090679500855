import { Amplify } from 'aws-amplify';
import type { WithAuthenticatorProps } from '@aws-amplify/ui-react';
import { withAuthenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import config from '../aws-exports';
import { NavLink } from 'react-router-dom';
import { Text, Link, Theme, Flex } from '@radix-ui/themes';
import { EnvelopeClosedIcon } from '@radix-ui/react-icons';
Amplify.configure(config);

export function Footer({ signOut, user }: WithAuthenticatorProps) {
  return (
    <Theme>
      <Flex 
        gap='8'
        justify="center"
        align="center"
        style={{
          position: 'relative',
          width: '100%',
          padding: '2rem 1rem 3rem 1rem',
          marginTop: '2rem',
          backgroundColor: 'transparent',
        }}
      >
        <Text className='rights'> &copy; 2024 FilmAssistant Inc. All rights reserved.</Text> 
        <Text className='name'> 
          <EnvelopeClosedIcon style={{ marginRight: '0.5rem' }} /> 
          accountservices@filmassistant.io
        </Text>
        <a 
          className='terms' 
          href="https://google.com" 
          target="_blank" 
          rel="noopener noreferrer"
        >
          <Text>Terms of Service</Text>
        </a>
      </Flex>
    </Theme>
  );
}

export default withAuthenticator(Footer);